import React from 'react';

const Dostavka = () => {
    return (<div>
    <h1>Договор доставки</h1>
    <p>
      Наша компания осущетсвляем доставку наших товаров посредством РУП «Белпочта».
      <br/>
      <br/>
      Сроки доставки зависят от региона и метода получения.
      <br/>
      <br/>
      Стоимость доставки зависит от веса това и метода получения по тарифам РУП «Белпочта».
      <br/>
      <br/>
      В случае задержки вашего заказа РУП «Белпочта» уведомит вас об этом посредством сообщения.
    </p>
  </div>);
}

export default Dostavka;