import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Politics from './pages/Politics';
import Agreement from './pages/Agreement';
import DogovorVozvrata from './pages/DogovorVozvrata';
import Dostavka from './pages/Dostavka';
import ThankYouPage from './pages/ThankYouPage';
import contentData from './content.json';

const App = () => {
    useEffect(() => {
      document.title = contentData.title;
    });

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/politics" element={<Politics />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/dogovor_vozvrata" element={<DogovorVozvrata />} />
        <Route path="/dostavka" element={<Dostavka />} />
        <Route path="/thank_you" element={<ThankYouPage />} />
      </Routes>
    </Router>
  )
}

export default App;
