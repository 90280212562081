import React from 'react';

const DogovorVozvrata = () => {
    return (<div>
        <h1 >Порядок возврата товара и денежных средств</h1>
        <p>Во избежание конфликтных ситуаций предлагаем вам ознакомиться с данным разделом, в
            котором приведены все основные правила взаимодействия продавцов и покупателей,
            касающиеся продаваемых нашим интернет-магазином товаров. Все эти правила закреплены
            законами и актами законодательства Республики Беларусь и носят обязательный характер как для
            продавцов, так и для покупателей.</p>
        <p>
            Также обращаем ваше внимание, что все товары, продаваемые в нашем интернет-магазине
            официально импортированы в Республику Беларусь и имеют все необходимые документы.
        </p>
        <p>По вопросам обмена или возврата товара, приобретенного в нашем интернет-магазине, вы
            можете обратиться к специалистам интернет-магазина по телефону или
            написав на электронную почту</p>
        <p>Стороны руководствуются Законом Республики Беларусь от 09.01.2002 N 90-З (ред. от 29.10.2015)
            "О защите прав потребителей"</p>
        <h1>Возврат товара</h1>
        <p><strong>Обмен и возврат товаров ненадлежащего качества</strong></p>
        <p>При обнаружении скрытых дефектов в товаре сразу сообщите нам об этом. Обмен товара или
            возврат денежных средств Покупателю осуществляется после проведения экспертизы сервисного
            центра.</p>
        <p><strong>Порядок замены товара ненадлежащего качества</strong></p>
        <p>Для осуществления замены товара ненадлежащего качества необходимо предоставить
            следующие документы:
            Заявление с просьбой о замене товара установленного образца.</p>
        <p>После рассмотрения предоставленных документов с Вами свяжутся в течение двух-трех дней и
            сообщат результат рассмотрения документов. Если будет принято положительное решение о
            замене товара, то с Вами договорятся о времени и товаре, на который должна будет произведена
            замена.</p>
        <p>При замене товара ненадлежащего качества Вам необходимо будет следующее:
            Товар ненадлежащего качества, замена которого производится, в полной комплектации;
            Расписку о том, что замена была произведена.</p>
        <p><strong>Обмен и возврат товаров надлежащего качества</strong></p>
        <p>В соответствии со ст. 28 Закона Республики Беларусь от 9 января 2002 г. № 90-З "О защите прав
            потребителей" предоставляем следующую информацию о порядке и сроках возврата товара:</p><p>
            Вы имеете право вернуть товар в течении 14 дней с момента приобретения при соблюдении
            следующих условий:
        </p>
        <p>Товар не был в эксплуатации, сохранил внешний вид и потребительские свойства, находится в
            оригинальной заводской упаковке;
        </p><p>
            Товар не включен в Перечень непродовольственных товаров надлежащего качества, не
            подлежащих обмену и возврату, утвержденный постановлением Совета Министров Республики
            Беларусь от № 778 14.06.2002 года. К таким товарам относятся технически сложные товары
            бытового назначении, иные товары с питанием от сети переменного тока.</p>
        <p>Возврат денежных средств осуществляется в том же порядке, в котором производилась его
            оплата:</p><p>
            При оплате товара наличными денежными средствами, возврат денежных средств Покупателю
            будет осуществлен таким же образом, после возврата товара Продавцу. Денежные средства за некачественный товар будут возвращены в срок до 7 календарных дней.
            Для этого необходимо написать заявление на возврат товара, предоставить некачественный
            товар, его упаковку и всю прилагающуюся документацию к нему, а также вернуть специалисту
            интернет-магазина чек на товар.</p>
        <p>При оплате товара банковской картой, через терминал или путем электронного платежа, возврат
            осуществляется на банковскую карту, с которой был произведен платеж. Срок поступления
            денежных средств на карту от 3 до 30 дней с момента осуществления возврата товара (более
            точные сроки можете уточнить у Вашего обслуживающего банка). </p>
        <h1>Выдержки из законодательных актов, регламентирующие возврат (замену) товара</h1>
        <p><strong>Закон Республики Беларусь от 9 января 2002 г. N 90-З «О защите прав потребителей» (в редакции
            от 29 октября 2015 г. № 313-З)</strong></p>
        <p><strong>Статья 20. Права потребителя в случае реализации ему товара ненадлежащего качества</strong></p>
        <p>1. Потребитель, которому продан товар ненадлежащего качества, если его недостатки не были
            оговорены продавцом, вправе по своему выбору потребовать:</p>
        <p>1.1. замены недоброкачественного товара товаром надлежащего качества;</p>
        <p>1.2. соразмерного уменьшения покупной цены товара;</p>
        <p>1.3. незамедлительного безвозмездного устранения недостатков товара;</p>
        <p>1.4. возмещения расходов по устранению недостатков товара.</p>
        <p>2. Потребитель вправе потребовать замены технически сложного товара или дорогостоящего
            товара в случае обнаружения его существенных недостатков. Перечень таких технически сложных
            товаров утверждается Правительством Республики Беларусь.</p>
        <p>3. Вместо предъявления указанных в пунктах 1 и 2 настоящей статьи требований потребитель
            вправе расторгнуть договор розничной купли-продажи и потребовать возврата уплаченной за
            товар денежной суммы.
        </p>
        <p>Существенный недостаток товара - неустранимый недостаток, либо недостаток, который не может
            быть устранен без несоразмерных расходов (в размере тридцати и более процентов от стоимости
            товара) либо несоразмерных затрат времени (превышающих максимально допустимые затраты
            времени), или выявляется неоднократно, или проявляется вновь после его устранения, либо
            другие подобные недостатки.</p>
        <p><strong>Статья 22. Безвозмездное устранение недостатков товара продавцом</strong></p>
        <p>1. Недостатки, обнаруженные в товаре, должны быть устранены продавцом (изготовителем,
            поставщиком, представителем, ремонтной организацией) безвозмездно и незамедлительно. В
            случае, если устранить недостатки незамедлительно не представляется возможным,
            максимальный срок их устранения не может превышать четырнадцати дней со дня предъявления
            потребителем требования об устранении недостатков товара.</p>
        <p><strong>Статья 23. Замена товара ненадлежащего качества</strong></p>
        <p>1. В случае обнаружения потребителем недостатков товара и предъявления требования о замене
            такого товара продавец (изготовитель, поставщик, представитель) обязан заменить такой товар
            незамедлительно, а при необходимости дополнительной проверки качества такого товара
            продавцом (изготовителем, поставщиком, представителем) - заменить его в течение
            четырнадцати дней со дня предъявления указанного требования.</p>
        <p><strong>Статья 28. Право потребителя на обмен и возврат товара надлежащего качества</strong></p>
        <p>1.Потребитель вправе в течение четырнадцати дней с момента передачи ему
            непродовольственного товара, если более длительный срок не объявлен продавцом, в месте
            приобретения или иных местах, объявленных продавцом, возвратить товар надлежащего качества
            или обменять его на аналогичный товар других размера, формы, габарита, фасона, расцветки или
            комплектации, произведя в случае разницы в цене необходимый перерасчет с продавцом.</p><p>
            2. Требование потребителя об обмене либо возврате товара подлежит удовлетворению, если
            товар не был в употреблении, сохранены его потребительские свойства и имеются доказательства
            приобретения его у данного продавца.</p><p>
            3. Перечень непродовольственных товаров надлежащего качества, не подлежащих обмену и
            возврату, утверждается Правительством Республики Беларусь.</p>
    </div>);
}

export default DogovorVozvrata;