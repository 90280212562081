import React from 'react';

function ThankYouPage() {
  return (
    <div className="thank-you-container">
      <h1>Спасибо за покупку!</h1>
      <p>Ваш заказ был успешно оформлен.</p>
    </div>
  );
}

export default ThankYouPage;
