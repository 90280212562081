import React from 'react';

const Agreement = () => {
    return (<div>
        <h1>Пользовательское соглашение</h1>

        <p>Настоящее пользовательское соглашение заключается между Вами – Клиентом, Рекламодателем, Администратором сайта и СРА - сетью.
        </p>
        <p><strong>Клиент</strong> – лицо, привлекаемое методами продвижения, на приобретение/отчуждение оферов на товары и/или услуги, предлагаемые Рекламодателем, имеющие намерение заказать или приобрести либо заказывающий, приобретающий или использующий товары исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности.
        </p>
        <p><strong>Рекламодатель</strong> – лицо, которое желает разместить офер в CPA-сети, с целью отчуждения определенного товара и/или услуги.
        </p>
        <p><strong>CPA-сеть</strong> (сокращение от Cost Per Action, то есть оплата за действие) – электронная среда деловой активности, в процессе которой между Рекламодателем и СРА-сетью складываются договорные взаимоотношения, в силу которых, Рекламодатель предлагает оферы на товар и/или услугу, а СРА-сеть, в свою очередь, предоставляет возможность разместить оферы на своем интернет-пространстве для поиска лиц, осуществляющих продвижение товаров/услуг рекламодателя в сети «Интернет».
        </p>
        <p><strong>Администратор сайта</strong> – лицо, указанное в качестве администратора сайта, при регистрации доменного имени, предоставляющее Рекламодателю возможность размещения информации на сайте.
        </p>
        <p><strong>Стороны</strong> – Клиент, Рекламодатель, CPA-сеть, Администратор сайта.
        </p>
        <p><strong>Сайт</strong> – интернет ресурс интернет-магазина, пребывающий под организационным управлением и принадлежащий Рекламодателю, на условиях права частной собственности.
        </p>
        <p><strong>Публичная оферта</strong> – адресованное администрацией неопределенному кругу лиц или нескольким конкретным лицам предложение, которое определенно, конкретно и выражает намерение лица, сделавшего предложение, считать себя заключившим данное пользовательское соглашение с адресатом, которым будет принято предложение.
        </p>
        <p><strong>Акцепт</strong> – полное принятие одной из Сторон условий публичной оферты СРА-сети на заключение данного пользовательского соглашения. Акцептированием публичной оферты происходит в момент начала пользования Сайтом (в том числе в ознакомительных целях) и его сервисами (услугами).
        </p>
        <p><strong>1. Общие положения</strong>
        </p>
        <p>1.1. Настоящее Пользовательское соглашение (далее по тексту – «Соглашение») определяет общие правила посещения, использования сервисов и нормы общего поведения на Сайте Клиентом и регулирует гражданско-правовые взаимоотношения, которые складываются между Клиентом и Рекламодателем, а также Клиентом, Рекламодателем и СРА-сетью в процессе их взаимодействия.
        </p>
        <p>1.2. Настоящее Соглашение может быть изменено по решению CPA-сети и/или Рекламодателя, в одностороннем порядке. CPA-сеть и/или Рекламодатель не несет обязательства личного уведомления Клиента о таких изменениях. Новая редакция Соглашения вступает в силу, с момента приобретения товара/услуги Рекламодателя Клиентом.
        </p>
        <p>1.3. Условия Соглашения распространяются на всех Клиентов сайта, без исключений.
        </p>
        <p>1.4. Клиент, начиная пользоваться Сайтом, подтверждает факт того, что ознакомился с положениями настоящего Соглашения в здравом уме и при ясной памяти, понимает их в полной мере и принимает условия пользования Сайтом в полной мере. При несогласии с положениями настоящего Соглашения (частично или в целом), лицо, выразившее такую волю, не имеет право пользоваться информационным полем Сайта.
        </p>
        <p><strong>2. Регуляция взаимодействия сторон</strong>
        </p>
        <p>2.1. Реализация предоставленных Сайтом услуг и (или) возможностей не предоставляет Клиенту каких-либо исключительных прав и привилегий.
        </p>
        <p>2.2. Стороны настоящего Соглашения достигли согласия о том, что СРА-сеть вправе размещать на Сайте рекламные блоки, баннеры, объявления в любых его областях, в том числе там, где размещается информация опубликованная Клиентом, без дополнительного согласия Клиента.
        </p>
        <p>2.3. Информация, размещаемая на Сайте СРА-сетью, за исключением информации размещаемой по поручению, либо лично Рекламодателем, является результатом интеллектуальной деятельности СРА-сети и все имущественные и личные неимущественные права на такую информацию, принадлежат СРА-сети, до тех пор, пока не будет установлено иное. При этом у Клиента не возникает, каких-либо исключительных прав на результат интеллектуальной деятельности СРА-сети, выраженной в графической, текстовой, аудио-видео форме, размещаемой СРА-сетью на Сайте.
        </p>
        <p>2.4. СРА-сеть не несет обязательств по защите нарушенных прав Клиента, в контексте урегулирования возникших на этой почве споров, в том числе, в судебном порядке.
        </p>
        <p>2.5. СРА-сеть не является владельцем/изготовителем товаров и/или услуг, размещаемых на Сайте и не несет ответственности за нарушение прав Клиента. Целью СРА-сети по настоящему Соглашению является сбор статистической информации о количестве потенциальных клиентов, заинтересованных в приобретении товаров и/или услуг, разрешенными Рекламодателем методами.
        </p>
        <p>2.6. Нарушение Клиентом или Рекламодателем авторских прав, принадлежащих СРА-сети и (или) другим лицам, влечет для нарушителя ответственность, предусмотренную положениями действующего законодательства Республики Беларусь.
        </p>
        <p>2.7. В случае выявления нарушения авторских прав Клиентом, путем незаконного размещения не принадлежащих Клиенту материалов, СРА-сеть изымает из свободного доступа такие материалы, по первому требованию законного правообладателя.
        </p>
        <p>2.8. Клиенту запрещается размещать на Сайте информацию, которая прямо, или косвенно содержит в себе общепринятые признаки порнографии, оскорбляющую, ущемляющую, наносящую ущерб чьему-либо достоинству, содержащую призывы к насилию, бесчинству и другим действиям, влекущим нарушения норм действующего законодательства, определенной территориальной юрисдикции, содержащую вредоносное программное обеспечение и (или) другую информацию, которая может принести вред третьим лицам.
        </p>
        <p>2.9. В случае нарушения условий п. 2.8. настоящего Соглашения и невыполнения требований СРА-сети, в том числе по изъятию такой информацию из публичного доступа, Клиенты Сайта несут ответственность, предусмотренную положениями настоящего Соглашения и(или) действующего законодательства Республики Беларусь. СРА-сеть в таком случае, имеет право убрать упомянутую в п. 2.8. информацию самостоятельно.
        </p>
        <p>2.10. СРА-сеть не несет ответственности за результаты посещения Клиентом сторонних (внешних) ресурсов, ссылки на которые могут быть размещены на Сайте. Под результатами понимается любой результат вне зависимости от его характера, а также тот, от наступления которого Клиент понес какие-либо материальные убытки, моральный ущерб и другие негативные проявления.
        </p>
        <p>2.11. Порядок ведения торговли дистанционным путем, права и обязанности Сторон соглашения, а также третьих лиц, особые требования к процессам взаимодействия Сторон и оформления рекламных площадок, регулируются в нормативном порядке – Законом Республики Беларусь "О рекламе".
            Правилами ведения торговли дистанционным путем, утвержденными Постановлением совета министров Республики Беларусь  31 от 15.01.2009, а также другими нормативными актами и настоящим Соглашением.
        </p>
        <p><strong>3. Права и обязанности Рекламодателя</strong>
        </p>
        <p>3.1. Рекламодатель обязан предложить Клиенту услуги по доставке товаров путем их пересылки почтовыми отправлениями или перевозки с указанием используемого способа доставки и вида транспорта.
        </p>
        <p>3.2. Рекламодатель обязан сообщить Клиенту о необходимости использования квалифицированных специалистов по подключению, наладке и пуску в эксплуатацию технически сложных товаров, которые по техническим требованиям не могут быть пущены в эксплуатацию без участия соответствующих специалистов.
        </p>
        <p>3.3. Рекламодатель не вправе без согласия Клиента выполнять дополнительные работы (оказывать услуги) за плату.
        </p>
        <p>3.4. Рекламодатель обязан до заключения договора розничной купли-продажи (далее – «Договор») предоставить Клиенту информацию об основных потребительских свойствах товара и адресе (месте нахождения) Рекламодателя, о месте изготовления товара, полном фирменном наименовании (наименовании) Рекламодателя, о цене и об условиях приобретения товара, о его доставке, сроке службы, сроке годности и гарантийном сроке, о порядке оплаты товара, а также о сроке, в течение которого действует предложение о заключении Договора.
        </p>
        <p>3.5. Рекламодатель в момент доставки товара обязан довести до сведения Клиента в письменной форме следующую информацию (для импортных товаров - на русском языке):
        </p>
        <p>3.5.1. наименование технического регламента или иное обозначение, установленное законодательством Республики Беларусь о техническом регулировании и свидетельствующее об обязательном подтверждении соответствия товара;
        </p>
        <p>3.5.2. сведения об основных потребительских свойствах товара (работ, услуг), а в отношении продуктов питания - сведения о составе (в том числе наименование использованных в процессе изготовления продуктов питания пищевых добавок, биологически активных добавок, информация о наличии в продуктах питания компонентов, полученных с применением генно-инженерно-модифицированных организмов), пищевой ценности, назначении, об условиях применения и хранения продуктов питания, о способах изготовления готовых блюд, весе (объеме), дате и месте изготовления и упаковки (расфасовки) продуктов питания, а также сведения о противопоказаниях для их применения при отдельных заболеваниях;
        </p>
        <p>3.5.3. цена в рублях и условия приобретения товара (выполнения работ, оказания услуг);
        </p>
        <p>3.5.4. сведения о гарантийном сроке, если он установлен;
        </p>
        <p>3.5.5. правила и условия эффективного и безопасного использования товаров;
        </p>
        <p>3.5.6. сведения о сроке службы или сроке годности товаров, а также сведения о необходимых действиях Клиента по истечении указанных сроков и возможных последствиях при невыполнении таких действий, если товары по истечении указанных сроков представляют опасность для жизни, здоровья и имущества Клиента или становятся непригодными для использования по назначению;
        </p>
        <p>3.5.7. место нахождения (адрес), фирменное наименование (наименование) изготовителя (Рекламодателя), место нахождения (адрес) организации (организаций), уполномоченной изготовителем (Рекламодателем) на принятие претензий от Клиента и производящей ремонт и техническое обслуживание товара, для импортного товара - наименование страны происхождения товара;
        </p>
        <p>(см. текст в предыдущей редакции)
        </p>
        <p>3.5.8. сведения об обязательном подтверждении соответствия товаров (услуг) обязательным требованиям, обеспечивающим их безопасность для жизни, здоровья Клиента, окружающей среды и предотвращение причинения вреда имуществу Клиента в соответствии с законодательством Республики Беларусь;
        </p>
        <p>3.5.9. сведения о правилах продажи товаров (выполнения работ, оказания услуг);
        </p>
        <p>3.5.10. сведения о конкретном лице, которое будет выполнять работу (оказывать услугу), и информация о нем, если это имеет значение исходя из характера работы (услуги);
        </p>
        <p>3.5.11 информация об энергетической эффективности товаров, в отношении которых требование о наличии такой информации определено в соответствии с законодательством Республики Беларусь об энергосбережении и о повышении энергетической эффективности.
        </p>
        <p>3.6. Рекламодатель обязан предоставить информацию Клиенту, если приобретаемый Клиентом товар был в употреблении или в нем устранялся недостаток (недостатки).
        </p>
        <p>3.7. Рекламодатель обязан проинформировать Клиента о товаре, включая условия его эксплуатации и правила хранения, доводится до Клиента путем размещения на товаре, на электронных носителях, прикладываемых к товару, в самом товаре (на электронной плате внутри товара в разделе меню), на таре, упаковке, ярлыке, этикетке, в технической документации или иным способом, установленным законодательством Республики Беларусь.
        </p>
        <p>3.8. Рекламодатель обязан информировать Клиента о сроке, в течение которого действует предложение о продаже товара/услуги на Сайте.
        </p>
        <p>3.9. Рекламодатель имеет право, как принять, так и отклонить предложение Клиента о пересылке товаров почтовым отправлением в адрес "До востребования".
        </p>
        <p>3.10. Рекламодатель обязан обеспечивать конфиденциальность персональных данных о Клиенте в соответствии с законодательством Республики Беларусь в области персональных данных.
        </p>
        <p>3.11. Рекламодатель предоставляет Клиенту каталоги, буклеты, проспекты, фотографии или другие информационные материалы, содержащие полную, достоверную и доступную информацию, характеризующую предлагаемый товар.
        </p>
        <p>3.12. При отказе Клиента от товара Рекламодатель обязан возвратить ему сумму, уплаченную Клиентом в соответствии с Договором, за исключением расходов Рекламодателя на доставку от Клиента возвращенного товара, не позднее чем через 10 дней с даты предъявления Клиентом соответствующего требования.
        </p>
        <p>3.13. В случае если Договор заключен с условием о доставке товара Клиенту, Рекламодатель обязан в установленный Договором срок доставить товар в место, указанное Клиентом, а если место доставки товара Клиентом не указано, то в место его жительства.
        </p>
        <p>3.14. Рекламодатель обязан передать товар Клиенту в порядке и сроки, которые установлены в Договоре.
        </p>
        <p>3.15. Рекламодатель обязан передать Клиенту товар, качество которого соответствует Договору и информации, представленной Клиенту при заключении Договора, а также информации, доведенной до его сведения при передаче товара (в технической документации, прилагаемой к товару, на этикетках, путем нанесения маркировки либо иными способами, предусмотренными для отдельных видов товаров).
        </p>
        <p>3.16. Если Рекламодатель при заключении Договора был поставлен Клиентом в известность о конкретных целях приобретения товара, Рекламодатель обязан передать Клиенту товар, пригодный для использования в соответствии с этими целями.
        </p>
        <p>3.17. Расходы на осуществление возврата суммы, уплаченной Клиентом в соответствии с Договором, несет Рекламодатель.
        </p>
        <p>3.18. Оплата товара Клиентом путем перевода средств на счет третьего лица, указанного Рекламодателем, не освобождает Рекламодателя от обязанности осуществить возврат уплаченной Клиентом суммы при возврате Клиентом товара как надлежащего, так и ненадлежащего качества.
        </p>
        <p>3.19. Рекламодатель, размещая информацию на сайте, как лично, так и поручая это третьим лицам, в том числе Администратору сайта, гарантирует, что имеет право на опубликование и использование размещаемых материалов, и самостоятельно несет ответственность за нарушений авторских прав третьих лиц.
        </p>
        <p><strong>4. Права и обязанности Клиента</strong>
        </p>
        <p>4.1. Клиент вправе отказаться от товара в любое время до его передачи, а после передачи товара - в течение 7 дней.
        </p>
        <p>4.2. Клиент вправе отказаться от товара в течение 3 месяцев с момента передачи товара, в случае если информация о порядке и сроках возврата товара надлежащего качества не была предоставлена в письменной форме в момент доставки товара.
        </p>
        <p>4.3. Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара. Отсутствие у Клиента указанного документа не лишает его возможности ссылаться на другие доказательства приобретения товара у данного Рекламодателя.
        </p>
        <p>4.4. Клиент не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный товар может быть использован исключительно приобретающим его Клиентом.
        </p>
        <p>4.5. Клиент обязан повторно оплатить стоимость услуг по доставке товара, в случае если доставка товара произведена в установленные Договором сроки, но товар не был передан Клиенту по его вине, последующая доставка производится в новые сроки, согласованные с Рекламодателем
        </p>
        <p>4.6. В случае если Клиенту передается товар с нарушением условий Договора, касающихся количества, ассортимента, качества, комплектности, тары и (или) упаковки товара, Клиент может не позднее 20 дней после получения товара известить Рекламодателя об этих нарушениях.
        </p>
        <p>4.7. Если обнаружены недостатки товара, в отношении которого гарантийные сроки или сроки годности не установлены, Клиент вправе предъявить требования в отношении недостатков товара в разумный срок, но в пределах 2 лет со дня передачи его Клиенту, если более длительные сроки не установлены нормативными актами или Договором.
        </p>
        <p>4.8. Клиент вправе предъявить требования к Рекламодателю в отношении недостатков товара, если они обнаружены в течение гарантийного срока или срока годности.
        </p>
        <p>4.9. Клиент, которому продан товар ненадлежащего качества, если это не было оговорено Рекламодателем, вправе по своему выбору потребовать:
        </p>
        <p>а) безвозмездного устранения недостатков товара или возмещения расходов на их исправление Клиентом или третьим лицом;
        </p>
        <p>б) соразмерного уменьшения покупной цены;
        </p>
        <p>в) замены на товар аналогичной марки (модели, артикула) или на такой же товар другой марки (модели, артикула) с соответствующим перерасчетом покупной цены. При этом в отношении технически сложных и дорогостоящих товаров эти требования Клиента подлежат удовлетворению в случае обнаружения существенных недостатков.
        </p>
        <p>4.10. Клиент вместо предъявления требований, указанных в пункте 4.9 настоящего Соглашения, вправе отказаться от исполнения Договора и потребовать возврата уплаченной за приобретенный товар суммы. По требованию Рекламодателя и за его счет Клиент должен возвратить товар с недостатками.
        </p>
        <p>4.11. Клиент вправе потребовать полного возмещения убытков, причиненных ему вследствие продажи товара ненадлежащего качества. Убытки возмещаются в сроки, установленные Законом Республики Беларусь "О защите прав потребителей" для удовлетворения соответствующих требований Клиента.
        </p>
        <p>4.12. Клиент вправе отказаться от исполнения Договора и потребовать возмещения причиненных убытков, при отказе Рекламодателя передать товар.
        </p>
        <p>4.13. При возврате товара ненадлежащего качества отсутствие у Клиента документа, подтверждающего факт и условия покупки товара, не лишает его возможности ссылаться на другие доказательства приобретения товара у Рекламодателя.
        </p>
        <p>4.14. Отказ или уклонение Рекламодателя от составления накладной или акта не лишают Клиента права требовать возврата товара и (или) возврата суммы, уплаченной Клиентом в соответствии с Договором.
        </p>
        <p>4.15. Клиент вправе отказаться от оплаты дополнительных работ (услуг), которые не оговоренные Договором, а если они оплачены, Клиент вправе потребовать от Рекламодателя возврата уплаченной сверх указанной суммы.
        </p>
        <p>4.16. По аналогии с инструкциями, изложенными в п. 4.16. настоящего Соглашения, Клиент обязуется действовать, в случае использования результатов интеллектуальной собственности, что принадлежать третьим лицам. Способ и порядок осуществления уточняется в процессе переговоров с правообладателем материалов.
        </p>
        <p><strong>5. Ответственность сторон</strong>
        </p>
        <p>5.1. СРА-сеть не несет ответственности за действия Клиента, которые повлекли нарушение прав третьих лиц, кроме случаев определенных действующим законодательством Республики Беларусь.
        </p>
        <p>5.2. СРА-сеть не несет ответственности за содержание информации, размещенной Рекламодателем и/или Клиентом.
        </p>
        <p>5.3. СРА-сеть не несет ответственности за содержание отзывов Клиентов Сайта. Отзывы Клиентов Сайта являются субъективными мнениями их авторов, никоим образом не претендующими на объективность. Они могут не совпадать с общественным мнением и не соответствовать действительности.
        </p>
        <p>5.4. Решение о выдаче/не выдаче персональных данных, принимается СРА-сетью, лишь на основании запроса, посланного лицом СРА-сети, в установленном нормами действующего законодательства порядке.
        </p>
        <p>5.5. СРА-сеть вправе не реагировать на запросы, обращения и письма, что не содержат реквизитов обращающегося (ФИО, контактные данные).
        </p>
        <p>5.6. СРА-сеть не несет ответственности за регистрационные данные, что были указаны Клиентом при взаимодействии с информационным полем Сайта.
        </p>
        <p>5.7. СРА-сеть имеет право, без объяснения причин ограничить, блокировать доступ Клиента (в т.ч. незарегистрированного) на Сайт, с частичным, или полным удалением информации, что была размещена Клиентом на Сайте. СРА-сеть обязуется рассмотреть претензию, оформленную в порядке предусмотренном разделом 5 соглашения, в течении 30 (тридцати) календарных дней с даты ее получения.
        </p>
        <p><strong>6. Порядок урегулирования споров</strong>
        </p>
        <p>6.1. В случае выявления на Сайте размещенной информации, содержащей в себе результаты интеллектуальной собственности, принадлежащие третьим лицам, правообладатель обязан:
        </p>
        <p>6.1.1. Составить претензию с указанием фактических и нормативных оснований, которые дают возможность СРА-сети передать информацию лицу, нарушающему права, или изъять информацию из публичного доступа.
        </p>
        <p>6.1.2. Прикрепить к претензии доказательства оригинальности результата интеллектуальной собственности (оригинальный экземпляр, другие документы, подтверждающие правообладание на объект авторского права).
        </p>
        <p>6.1.3. Отправить пакет документов, упомянутых в положениях п.п. 6.1.1., 6.1.2. настоящего Соглашения, на электронный почтовый ящик.
        </p>
        <p><strong>7. Прочие условия</strong>
        </p>
        <p>7.1. Все возможные ситуации, споры, вытекающие из взаимоотношений Клиента и Рекламодателя, а так же Клиента, Рекламодателя и СРА-сети, не урегулированные этим Соглашением, решаются в порядке, определенном нормами действующего законодательства Республики Беларусь.
        </p>
        <p>7.2. Стороны по настоящему Соглашению осознают объем прав и обязанностей, порожденных взаимоотношениями лиц, упомянутых этим Соглашением, и в полной мере отдают отчет своим действиям, понимая юридическую природу последствий таких действий, в полной мере.
        </p>
        <p>7.3. Бездействие со стороны СРА-сети в случае нарушения кем-либо из Клиентов положений Соглашения, не лишает СРА-сети права предпринять позднее соответствующие действия в защиту своих интересов и защиту охраняемых законом прав.
        </p>

    </div>);
}

export default Agreement;